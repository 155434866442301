// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container{
    text-align: -webkit-center;
}
.register-form{
    display: flex;
    justify-content: center;
    margin-top: 200px;
    background: #635c5c;
    width: 65%;
    text-align: left;
    border-radius: 3px;
    padding: 15px 15px 15px 15px;
}
.register-btn button{
    width: 40%;
    margin-top: 10px;
    text-align: -webkit-center;
    border: none;
    border-radius: 2px;
    color: black;
    background: white;
}
.register-btn{
    text-align: right;
}
.logo img{
    width: 100%;
    height: 130px;
    object-fit: contain;
    
}
.register{
    overflow-x: hidden;
}
form{
    text-align: left;
    margin-top: 20px;
}
@media(max-width: 768px){
   form{
    margin-top: 20px;
   }
   .register-form {
    margin-top: 100px;
   }
}
@media(max-width: 430px){   
    .register-form{
        width: 100%;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Register/Register.css"],"names":[],"mappings":";AACA;IACI,0BAA0B;AAC9B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;IACnB,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,4BAA4B;AAChC;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;;AAEvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;GACG;IACC,gBAAgB;GACjB;GACA;IACC,iBAAiB;GAClB;AACH;AACA;IACI;QACI,WAAW;IACf;;AAEJ","sourcesContent":["\r\n.container{\r\n    text-align: -webkit-center;\r\n}\r\n.register-form{\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 200px;\r\n    background: #635c5c;\r\n    width: 65%;\r\n    text-align: left;\r\n    border-radius: 3px;\r\n    padding: 15px 15px 15px 15px;\r\n}\r\n.register-btn button{\r\n    width: 40%;\r\n    margin-top: 10px;\r\n    text-align: -webkit-center;\r\n    border: none;\r\n    border-radius: 2px;\r\n    color: black;\r\n    background: white;\r\n}\r\n.register-btn{\r\n    text-align: right;\r\n}\r\n.logo img{\r\n    width: 100%;\r\n    height: 130px;\r\n    object-fit: contain;\r\n    \r\n}\r\n.register{\r\n    overflow-x: hidden;\r\n}\r\nform{\r\n    text-align: left;\r\n    margin-top: 20px;\r\n}\r\n@media(max-width: 768px){\r\n   form{\r\n    margin-top: 20px;\r\n   }\r\n   .register-form {\r\n    margin-top: 100px;\r\n   }\r\n}\r\n@media(max-width: 430px){   \r\n    .register-form{\r\n        width: 100%;\r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
