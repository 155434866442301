// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-responsive{
    padding: 10px 30px;
}
.no-customer{
    text-align: center;
    margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Userlist/Userlist.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".table-responsive{\r\n    padding: 10px 30px;\r\n}\r\n.no-customer{\r\n    text-align: center;\r\n    margin-top: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
