import React, { useState, useEffect } from 'react';
import '../Login/Login.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
// import 'sweetalert2/src/sweetalert2.scss';

function Login() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);  // State to manage password visibility

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);  // Toggle password visibility
    };

    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = sessionStorage.getItem('access_token');
        if (accessToken) {
            navigate('/trade');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch('https://stockgenius.co.in/login/', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();
            if (data.access != null) {
                sessionStorage.setItem('access_token', data.access);
                sessionStorage.setItem('refresh_token', data.refresh);
                sessionStorage.setItem('admin', data.admin);
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Login Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate('/trade');
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: "User not found",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            console.log(data.access);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='login'>
            <div className='container'>
                <div className='login-text container'>
                    <h4 className='welcome'>Welcome To</h4>
                    <h4 className='welcome-name' style={{
                        background: 'linear-gradient(to top, rgb(239 215 153 / 74%), rgb(233 178 63))', WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                    }}>STOCK GENIUS</h4>
                </div>
                <div className='login-form row'>
                    <div className='logo col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                        <img src="assets/logo.png" alt="logo" />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                        <form onSubmit={handleSubmit}>
                            <label>Username</label>
                            <input
                                type='text'
                                name='username'
                                value={formData.username}
                                onChange={handleChange}
                                className='form-control'
                            />

                            <label>Password</label>
                            <div className='password-container'>
                                <input
                                    type={showPassword ? 'text' : 'password'}  // Toggle input type based on state
                                    name='password'
                                    value={formData.password}
                                    onChange={handleChange}
                                    className='form-control'
                                />
                               <div className='eye'>
                               <button 
                                    type='button'
                                    className='toggle-password-btn'
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>} {/* Show different icons based on the state */}
                                </button>
                                </div>
                            </div>
                            <br />
                            <div className='login-btn'>
                                <button type='submit'>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div className='footer-card'>
                    <span>Contact us : +91 7736131404</span>
                    <br />
                    <span>+91 8714047531</span>
                </div>
            </div>
        </div>
    );
}

export default Login;
