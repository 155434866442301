import React, { useEffect, useState } from 'react';
import '../Trade/Trade.css';
import '../Userlist/Userlist.css';
import Swal from 'sweetalert2';

function Userlist() {
    const [admin, setAdmin] = useState(null);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const storedAdmin = sessionStorage.getItem('admin');
        setAdmin(storedAdmin);

        if (storedAdmin === 'true') {
            const accessToken = sessionStorage.getItem('access_token');
            // const refresh_token=sessionStorage.getItem('refresh_token');
            fetchUserList(accessToken);
        }
    }, []);

    const fetchUserList = (accessToken) => {
        fetch('https://stockgenius.co.in/non-admin-users/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(data => {
                    if (data.code === 'token_not_valid') {
                        // Handle token refresh
                        refreshToken();
                    } else {
                        throw new Error(data.detail || 'Failed to fetch user list');
                    }
                });
            }
        })
        .then(data => setUserList(data))
        .catch(error => console.error('Error:', error));
    };

    const refreshToken = () => {
        const access_token = sessionStorage.getItem('access_token');
        if (refreshToken) {
            fetch('https://stockgenius.co.in/api/token/refresh/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(access_token)
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to refresh token');
                }
            })
            .then(data => {
                sessionStorage.setItem('access_token', data.access);
                fetchUserList(data.access);
            })
            .catch(error => console.error('Error:', error));
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Session expired, please log in again',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const handleDelete = (userId) => {
        const accessToken = sessionStorage.getItem('access_token');
        if (accessToken) {
            fetch(`https://stockgenius.co.in/delete-user/${userId}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then(response => {
                if (response.ok) {
                    Swal.fire({
                        title: "Are you sure?",
                        text: "You want to Delete User",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Delete User!"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          Swal.fire({
                            title: "Deleted!",
                            text: "User deleted successfully",
                            icon: "success"
                          });
                          setUserList(userList.filter(user => user.id !== userId));
                        }
                      });
                } else {
                    return response.json().then(data => {
                        if (data.code === 'token_not_valid') {
                            refreshToken();
                        } else {
                            Swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: data.detail || "An error occurred",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    });
                }
            })
            .catch(error => console.error('Error:', error));
        }
    };

    return (
        <div>
            <div className='header'>
                <div className='main-logo'>
                    <img src="assets/logo.png" alt="logo" />
                </div>
                {/* <p className='title'>Stock Genius</p> */}
            </div>
            <div className='table-responsive'>
                {userList.length > 0 ? (
                    <table className="table table-bordered border-primary" style={{ color: 'white' }}>
                        <thead>
                            <tr>
                                <th scope="col">Username</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {userList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.username}</td>
                                    <td><i className="bi bi-trash3-fill" style={{ color: 'red' }} onClick={() => handleDelete(item.id)}></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>
                        <h1 className='no-customer'>No Customer Registered</h1>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Userlist;
