import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Register/Register.css';
import Swal from 'sweetalert2';

function Register() {
    const [adminset, setAdminset] = useState(false); // Initialize as false
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        admin: false // Initialize as false
    });

    const [showPassword, setShowPassword] = useState(false); 

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch access token from localStorage
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            // If no token is found, redirect to the login page
            navigate('/');
        }
    }, [navigate]); // Add navigate to dependencies to avoid warnings

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);  // Toggle password visibility
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Update formData with the current adminset value before sending it
        const updatedFormData = { ...formData, admin: adminset };
        
        try {
            const res = await fetch('https://stockgenius.co.in/register/', {
                method: 'POST',
                body: JSON.stringify(updatedFormData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await res.json();
            console.log('result',data);
            if (data[0] === 'user_registered_successfully') {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Register Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate('/trade');
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: "Username Already Registered",
                    showConfirmButton: false,
                    timer: 1500
                });
            }

            
        } catch (error) {
            
        }
    };

    const makeAdmin = (e) => {
        setAdminset(e.target.checked); // Use checked property to toggle the state
    };

    return (
        <div className='register'>
            <div className='container'>
                <div className='register-form row'>
                    <div className='logo col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                        <img src="assets/logo.png" alt="logo" />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                        <form onSubmit={handleSubmit}>
                            <label>Username</label>
                            <input
                                type='text'
                                name='username'
                                value={formData.username}
                                onChange={handleChange}
                                className='form-control'
                            />

                            <label>Password</label>
                            <div className='password-container'>
                                <input
                                    type={showPassword ? 'text' : 'password'}  // Toggle input type based on state
                                    name='password'
                                    value={formData.password}
                                    onChange={handleChange}
                                    className='form-control'
                                />
                               <div className='eye'>
                               <button 
                                    type='button'
                                    className='toggle-password-btn'
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>} {/* Show different icons based on the state */}
                                </button>
                                </div>
                            </div>
                            <div className="form-check form-switch mt-2">
                                <input className="form-check-input" type="checkbox" onChange={makeAdmin} role="switch" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Make as Admin</label>
                            </div>
                            <br />
                            <div className='register-btn'>
                                <button type='submit'>Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
