import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Trade from './Trade/Trade';
import Login from './Login/Login';
import Register from './Register/Register';
import Userlist from './Userlist/Userlist';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/register-user-by-admin" element={<Register />} />
          <Route path="/user-list-to-admin" element={<Userlist />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
